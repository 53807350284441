import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { LibraryFilterWidgetComponent } from './library-filter-widget.component';

@NgModule({
  declarations: [LibraryFilterWidgetComponent, FilterPanelComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ClickOutsideModule, IconComponent],
  exports: [LibraryFilterWidgetComponent, FilterPanelComponent],
})
export class LibraryFilterWidgetModule {}
