import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CLibraryFilterData } from '@app/store/models';
import {
  AccountService,
  AuthService,
  ConfigService,
  ContextService,
  LanguageService,
  PlatformService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getUserServiceCategories } from '@store/reducers/content.reducer';
import { getLibraryRouteData } from '@store/reducers/library.reducer';
import {
  ContentTypeEnum,
  EContentOrder,
  Language,
  Subject as BaseSubject,
  Router as BaseRouter,
  SubjectLevelsRegistry,
  SubjectsRegistry,
  User,
  SubjectCategoryEnum,
  nativeSpokenLevel,
  UserServiceCategory,
  EContentPanelRoute,
} from 'lingo2-models';
import { combineLatest, filter, takeUntil } from 'rxjs';
import { filterList, filteringContentTypes } from './library-filter-widget.constants';
import { EFilterList, ILibraryFilter } from './library-filter-widget.types';

export enum LevelsCategoryIdEnum { // TODO вынести в константы lingo2-models
  languages = 1,
  non_categorized = 2,
}

@Component({
  selector: 'app-library-filter-widget',
  templateUrl: './library-filter-widget.component.html',
  styleUrls: ['./library-filter-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibraryFilterWidgetComponent extends ChangableComponent implements OnInit, OnDestroy {
  public openedPanelFilter: EFilterList = null;
  public isDisplayApply: boolean;
  public filterList = filterList;
  public baseRoute: string;
  public eFilterList = EFilterList;
  public expandFilterBodyState: boolean;
  public debug$ = this.contextService.debug$;
  public isTeacher: boolean;

  private _me: User;
  private _filterData: CLibraryFilterData;
  private _subjects: SubjectsRegistry;
  private _subjectLevels: SubjectLevelsRegistry;
  private _nativeSubjects: BaseSubject[] = [];
  private _foreignSubjects: BaseSubject[] = [];
  private _languages: Language[];
  private _userServiceCategory: UserServiceCategory[] = [];
  private _orderingTypes: EContentOrder[] = [
    EContentOrder.popularity_week,
    EContentOrder.popularity_year,
    EContentOrder.date_asc,
    EContentOrder.date_desc,
  ];

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private contextService: ContextService,
    private configService: ConfigService,
    private authService: AuthService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private router: Router,
    private readonly store: Store,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    combineLatest([
      this.contextService.me$.pipe(filter((me) => !!me)),
      this.configService.subjectsV2$.pipe(filter((subjects) => !!subjects)),
      this.configService.subjectLevels2$,
      this.languageService.language$,
      this.configService.languages$,
      this.store.select(getUserServiceCategories),
      this.store.select(getLibraryRouteData).pipe(filter((libraryFilterData) => !!libraryFilterData)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([me, subjects, subjectLevels, language, languages, userServiceCategory, libraryFilterData]) => {
          this._me = me;
          this.isTeacher = AccountService.isAsIfTeacher(me);
          this._subjects = subjects;
          this._subjectLevels = subjectLevels;
          // декомпозиция, потому что иначе не сортируется
          this._languages = [...languages].sort((a, b) => a.title.localeCompare(b.title));
          this._userServiceCategory = userServiceCategory;

          const nativeSpokenIds = this.spokenLanguageIds;
          if (nativeSpokenIds.length > 0) {
            this._nativeSubjects = this._subjects.native.filter((_s) =>
              nativeSpokenIds.find((_nId) => _nId === +_s.language_id),
            );
          } else {
            this._nativeSubjects = this._subjects.native.filter((_s) =>
              nativeSpokenIds.find((_nId) => _nId === +language.id),
            );
          }
          // copist: фильтр дисциплин всегда показывать все иностранные - не понимаю, почему я не могу изучать русский, если он мой родной
          // this._foreignSubjects = this._subjects.foreign
          //   .filter((_s) => !nativeSpokenIds.find((_nId) => _nId === +_s.language_id));
          this._foreignSubjects = this._subjects.foreign;

          if (this.authService.isAuthenticated && !this.isTeacher) {
            this.filterList[0].children[0].options = this._foreignSubjects.filter(
              (subject) => subject?.stats?.content_count > 0,
            );
            this.filterList[0].children[1].options = this._nativeSubjects;
            this.filterList[0].children[2].options = this._subjects.non_categorized.filter(
              (subject) => subject?.stats?.content_count > 0,
            );
          } else {
            this.filterList[0].children[0].options = this._foreignSubjects;
            this.filterList[0].children[1].options = this._nativeSubjects;
            this.filterList[0].children[2].options = this._subjects.non_categorized;
          }
          const indexItem = this.filterList.findIndex((_item) => _item.name === String(EFilterList.language));
          this.filterList[indexItem].options = this._languages;

          this._filterData = libraryFilterData;
          this.baseRoute = libraryFilterData.baseRoute;
          const list = this.itemLvl1('contentType');
          list.display = false;
          if (libraryFilterData.baseRoute === EContentPanelRoute.lessons) {
            this.contentTypesTransform();
            list.display = true;
          }
          if (libraryFilterData.baseRoute === EContentPanelRoute.classes) {
            this.userServiceCategoryTransform();
            list.display = true;
          }
          this.orderingTransform();
          this.setSubjects();
          this.setContentOrder();
          this.setContentTypes();
          this.setLanguages();
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public get filterData(): CLibraryFilterData {
    return this._filterData;
  }

  public onToggleFilterBody() {
    this.expandFilterBodyState = !this.expandFilterBodyState;
  }

  private setLanguages() {
    const languageCodes = this._filterData.languageCodes;
    const list = this.itemLvl1('language');
    list.selected = this._languages.filter((_l) => !!languageCodes.find((_code) => _code === _l.code));
  }

  private setContentTypes() {
    const contentTypeCodes = this._filterData.contentTypeCodes;
    const list = this.itemLvl1('contentType');
    list.selected = list.options.filter((_t) => !!contentTypeCodes.find((_code) => _code === _t.code));
  }

  private setContentOrder() {
    const contentOrder = this._filterData.contentOrder;
    const list = this.itemLvl1('order');
    list.selected = contentOrder ? list.options.filter((_o) => _o.code === contentOrder) : [];
  }

  private setSubjects() {
    const subjectCodes = this._filterData.subjectCodes;
    const list = this.itemLvl1('subject');
    list.selected = [...this._nativeSubjects, ...this._foreignSubjects, ...this._subjects.non_categorized].filter(
      (_s) => !!subjectCodes.find((_code) => _code === _s.code),
    );
  }

  /**
   * Список ID языков, которымм владеет текущий пользователь + язык интерфейса
   */
  private get spokenLanguageIds(): number[] {
    const nativelanguageIds = this._me.spoken_languages
      .filter((lang) => +lang.level_id === nativeSpokenLevel)
      .map((lang) => +lang.language_id);

    return [...nativelanguageIds, ...this.uiLanguageIds];
  }

  /**
   * ID языков интерфейса
   */
  private get uiLanguageIds(): number[] {
    const ids: number[] = [];

    // if (this.isBrowser) {
    //   /* список языков браузера */
    //   const langCode = this.languageService.findClosestLanguageCode((navigator.languages || []) as string[], false);
    //   const lang = LanguageService.getLanguageByCode(langCode);
    //   if (lang) {
    //     ids.push(lang.id);
    //   }
    // }
    //
    // /** язык текущей локали интерфейса */
    // const uiLang = LanguageService.getLanguageByCode(this.translate.currentLang);
    // if (uiLang) {
    //   ids.push(uiLang.id);
    // }

    return ids;
  }

  public onOpenPanel(name: EFilterList) {
    this.setTimeout(() => {
      this.openedPanelFilter = name;
      this.markForCheck();
    }, 200);
  }

  public closePanel() {
    this.openedPanelFilter = null;
  }

  private itemLvl1(name: EFilterList | string): ILibraryFilter {
    const index = this.filterList.findIndex((item) => item.name === name);
    return index >= 0 ? this.filterList[index] : null;
  }

  /**
   * устанавливаем правильный список уровней в зависимости от дисциплин
   */
  private displayLevelChange() {
    const list = this.itemLvl1('subject');
    const listLevel = this.itemLvl1('level');

    const levelsCategoryId = this.levelsCategoryId(list.selected);

    listLevel.options = levelsCategoryId ? this._subjectLevels[levelsCategoryId] : [];
    listLevel.display = list.selected.length === 1;
    if (!listLevel.display) {
      listLevel.selected = [];
    }
  }

  private levelsCategoryId(subjects: BaseSubject[]): number {
    if (!subjects.length) {
      return 0;
    }
    const levelsCategoryLanguage = subjects.filter(
      (_s) => +_s.levels_category_id === Number(LevelsCategoryIdEnum.languages),
    );
    const levelsCategoryNonCategorized = subjects.filter(
      (_s) => +_s.levels_category_id === Number(LevelsCategoryIdEnum.non_categorized),
    );
    if (subjects.length === levelsCategoryLanguage.length) {
      return 1;
    } else if (subjects.length === levelsCategoryNonCategorized.length) {
      return 2;
    }
    return 0;
  }

  public selectedOptions(value: any, listItemName: EFilterList) {
    const list = this.itemLvl1(listItemName);
    if (list.type === 'checkbox') {
      const beforeSelectedId = list.selected.findIndex((_value) => +_value.id === +value.id);
      if (beforeSelectedId >= 0) {
        list.selected.splice(beforeSelectedId, 1);
      } else {
        list.selected.push(value);
      }
    } else if (list.type === 'subjects') {
      const beforeSelectedId = list.selected.findIndex((_value) => +_value.id === +value.id);
      if (beforeSelectedId >= 0) {
        list.selected.splice(beforeSelectedId, 1);
      } else {
        list.selected = [value];
      }
    } else {
      list.selected = [value];
      this.closePanel();
    }
    /** @see https://app.clickup.com/t/gn6ymh */
    if (listItemName === EFilterList.subject) {
      const subject = value as BaseSubject;
      if (subject.category_id === SubjectCategoryEnum.foreign_language) {
        const language = this._languages.find((l) => l.id === subject.language_id);
        if (language) {
          this.selectedOptions(language, EFilterList.language);
        }
      }
    }
    this.displayLevelChange();
    this.isDisplayApply = true;
  }

  public selectedFilters(filterItem: ILibraryFilter): Array<{ id: number; title: string; value: any }> {
    const options = filterItem.children
      ? filterItem.children.reduce((acc, _item) => [...acc, ..._item.options], [])
      : filterItem.options;
    const property = filterItem.children ? filterItem.children[0].property : filterItem.property;

    if (!options.length) {
      return;
    }

    return filterItem.selected.map((_s) => {
      const option = options.find((_t) => +_t.id === +_s.id);
      return {
        id: option.id,
        title: option[property.title],
        value: option[property.value],
      };
    });
  }

  public onRemoveFilterOptions(optionItem, listItemName: EFilterList) {
    const list = this.itemLvl1(listItemName);
    const indexItem = list.selected.findIndex((_i) => +_i.id === +optionItem.id);
    const newList = indexItem >= 0 ? list.selected.splice(indexItem, 1) : list.selected;
    this.displayLevelChange();
    this.isDisplayApply = true;
  }

  public subjectsCode() {
    const list = this.itemLvl1('subject');
    return list.selected.map((_item) => _item.code);
  }

  private contentTypesTransform() {
    if (!this._filterData) {
      return;
    }

    const list = this.itemLvl1('contentType');
    list.options = filteringContentTypes.map((contentType) => {
      const code = BaseRouter.getNameByContentType(contentType);
      const title = `content-types-title.${ContentTypeEnum[contentType]}`;

      return {
        id: contentType,
        code,
        title,
      };
    });
  }

  private userServiceCategoryTransform() {
    const list = this.itemLvl1('contentType');
    list.options = this._userServiceCategory.map((_usc) => ({
      id: _usc.id,
      code: _usc.code,
      title: _usc.title,
    }));
  }

  private orderingTransform() {
    if (!this._filterData) {
      return;
    }

    const list = this.itemLvl1('order');
    const keys = Object.keys(this._orderingTypes);
    let i = 0;
    list.options = keys.map((orderType) => ({
      id: i++,
      code: this._orderingTypes[orderType],
      title: `library.content-filter.order.${this._orderingTypes[orderType]}`,
    }));
  }

  public contentTypesCode() {
    const list = this.itemLvl1('contentType');
    return list.selected.map((contentType) => contentType.code);
  }

  public contentOrder(): EContentOrder {
    const list = this.itemLvl1('order');
    const [first, ...other] = list.selected.map((contentType) => EContentOrder[contentType.code]);
    return (first as EContentOrder) || EContentOrder.popularity_week;
  }

  public contentLanguageCodes() {
    const list = this.itemLvl1('language');
    return list.selected.map((f) => f.code);
  }

  public onApplyFilter() {
    const libRouter = new LibraryRouter();

    libRouter.setBaseRoute(this._filterData.baseRoute);
    libRouter.setContentMode(this._filterData.contentMode);
    libRouter.setSubjects(this.subjectsCode());
    libRouter.setContentTypes(this.contentTypesCode());
    libRouter.setOrder(this.contentOrder());
    libRouter.setLanguageCodes(this.contentLanguageCodes());

    const link = libRouter.getLink();
    this.router.navigate([link.path], { queryParams: link.parameters }).then();
    this.isDisplayApply = false;
  }

  public isClearedAllFilters(): boolean {
    return !!this.filterList.filter((_item) => _item.selected.length).length;
  }

  public onClearAllFilters() {
    this.filterList.map((_item) => (_item.selected = []));
    this.isDisplayApply = true;
  }

  public filtersForAllCategories() {
    return this.filterList.reduce((acc, item) => acc + item.selected.length, 0);
  }

  public trackByFn(index) {
    return index;
  }
}
