<div class="library-filter-widget">
  <div class="header"
       [class.expand]="expandFilterBodyState"
       [class.filters]="filtersForAllCategories() !== 0"
       (click)="onToggleFilterBody()">
    <div class="title-block">
      <h3 class="title bold">{{ 'library.filter-widget.header-title' | translate }}</h3>
      <ng-template [ngIf]="filtersForAllCategories() !== 0">
        <span class="selected-text normal">
          {{ 'library.filter-widget.header-text' | translate }} {{ filtersForAllCategories() }}
        </span>
      </ng-template>
    </div>
    <div class="icon-wrap">
      <app-icon width="12" height="7" file="chevron" icon="up"></app-icon>
    </div>
  </div>
  <div class="filter-body" [class.expand]="expandFilterBodyState">
    <pre *ngIf="debug$ | async">filterData: {{ filterData | json }}</pre>
    <ul class="list">
      <li *ngFor="let filterItem of filterList; let i = index; trackBy: trackByFn" class="list-item" [class.hidden]="!filterItem.display">
        <div class="menu-item">
          <span class="label bold">{{ filterItem.label | translate }}</span>
          <span class="add-filter normal" (click)="onOpenPanel(filterItem.name)">
          {{ 'library.filter-widget.add-filter' | translate }}
        </span>
          <app-filter-panel
            *ngIf="openedPanelFilter === filterItem.name"
            [filter]="filterItem"
            [selectedList]="filterItem.selected"
            (selectedOptions)="selectedOptions($event, filterItem.name)"
            (openedPanel)="closePanel()">
          </app-filter-panel>
          <div class="selected-wrap">
            <div *ngFor="let optionItem of selectedFilters(filterItem); trackBy: trackByFn"
                 class="selected-filter" (click)="onRemoveFilterOptions(optionItem, filterItem.name)">
              <span class="selected-label normal">{{ optionItem.title | translate }}</span>
              <app-icon class="remove-icon" icon="remove-option"></app-icon>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div *ngIf="isDisplayApply"
         class="apply medium"
         (click)="onApplyFilter()">
      {{ 'library.filter-widget.apply' | translate }}
    </div>

    <div *ngIf="isClearedAllFilters()"
         class="clear medium"
         (click)="onClearAllFilters()">
      {{ 'library.filter-widget.clear' | translate }}
    </div>
  </div>
</div>
