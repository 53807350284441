import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { SubjectCategoryEnum } from 'lingo2-models';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ILibraryFilter } from '../library-filter-widget.types';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent extends ChangableComponent implements OnInit {
  @Input() filter: ILibraryFilter;
  @Input() selectedList: any[];
  @Output() openedPanel = new EventEmitter<boolean>();
  @Output() selectedOptions = new EventEmitter<any>();

  public level2 = null;
  public searchTerm = '';
  public searchList: any[] = [];

  private heightOption = 26;
  private search$ = this.register(new Subject<boolean>());

  constructor(public errorNotificationService: ErrorNotificationService, protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit() {
    this.search$.pipe(debounceTime(500), takeUntil(this.destroyed$)).subscribe({
      next: () => {
        this.filterSubjectsByTerm();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  public onClosePanel() {
    this.openedPanel.emit(false);
  }

  public onChangeOption(value: any, type = null) {
    this.selectedOptions.emit(value);
    if (type === 'subjects') {
      this.onClosePanel();
    }
  }

  public onSearchSubjects() {
    this.search$.next(true);
  }

  private filterSubjectsByTerm() {
    const filtered: any[] = [];
    const title = this.filter.property.title;
    const list = this.filter.children
      ? this.filter.children.reduce((acc, _item) => [...acc, ..._item.options], [])
      : this.filter.options;

    if (!!this.searchTerm) {
      list.map((_item) => {
        if (_item[title].toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0) {
          filtered.push(_item);
        }
      });
    }

    this.searchList = filtered;
    this.markForCheck();
  }

  public isNativeSubject(subject): boolean {
    const nativeSubjects = this.filter.children.filter((subj) => subj.name === 'native')[0];
    if (!!nativeSubjects.options.filter((i) => i.language_id === subject.language_id).length) {
      return subject.category_id === SubjectCategoryEnum.native_language;
    } else {
      return false;
    }
  }

  public isForeignSubject(subject): boolean {
    const nativeSubjects = this.filter.children.filter((subj) => subj.name === 'native')[0];
    if (!!nativeSubjects.options.filter((i) => i.language_id === subject.language_id).length) {
      return subject.category_id === SubjectCategoryEnum.foreign_language;
    } else {
      return false;
    }
  }

  public checked(option: any): boolean {
    const value = this.filter.property.value;
    return !!this.selectedList.find((_option) => _option[value] === option[value]);
  }

  public height(length: number, padding = 0, lengthSearch = 0, paddingSearch = 0) {
    return lengthSearch ? lengthSearch * this.heightOption + paddingSearch : length * this.heightOption + padding;
  }

  public trackByFn(index, item): string {
    return item.id;
  }
}
