<div *ngIf="filter"
     class="filter-item"
     [style.height.px]="height(filter.children ?
     filter.children.length : filter.options.length, filter.children ? 60 : 20, searchList.length, 60)"
     (clickOutside)="onClosePanel()">
  <label *ngIf="!!filter.placeholder" class="search-wrap">
    <input class="input normal"
           [placeholder]="filter.placeholder | translate"
           [(ngModel)]="searchTerm"
           (keyup)="onSearchSubjects()">
  </label>

  <ng-template [ngIf]="searchTerm" [ngIfElse]="content">
    <div class="search-options">
      <div class="scroll">
        <ul class="search-list">
          <ng-template [ngIf]="searchList.length" [ngIfElse]="noSearch">
            <li *ngFor="let searchItem of searchList; let i = index; trackBy: trackByFn" class="search-item">
              <label class="option-wrap">
                <input type="checkbox"
                       [value]="searchItem"
                       [checked]="checked(searchItem)"
                       (change)="onChangeOption(searchItem)"
                       class="input">
                <i class="input-checkbox">
                  <app-icon width="10" height="10" icon="checkbox-arrow"></app-icon>
                </i>
                <span class="label normal">{{ searchItem[filter.property.title] | translate }}</span>
              </label>
            </li>
          </ng-template>
          <ng-template #noSearch>
            <div class="not-found-wrap">
              <span class="not-found normal">{{ 'library.filter-widget.not-found' | translate }}</span>
            </div>
          </ng-template>
        </ul>
      </div>
    </div>
  </ng-template>

  <ng-template #content>
    <ng-template [ngIf]="filter.children" [ngIfElse]="flatList">
      <div class="options-lvl1">
        <ul class="options-list">
          <li *ngFor="let optionLvl1 of filter.children; let i = index; trackBy: trackByFn" class="option-item-lvl1">
            <div class="option"
                 (mouseover)="level2 = i"
                 (mouseout)="level2 = null">
              <span class="label normal">{{ optionLvl1.label | translate }}</span>
              <app-icon class="icon" icon="subject-select-arrow"></app-icon>

              <div class="options-lvl2"
                   [class.expand]="level2 === i"
                   [style.height.px]="height(optionLvl1.options.length)">
                <div class="scroll">
                  <ul class="options-list-lvl2">
                    <li *ngFor="let optionLvl2 of optionLvl1.options; trackBy: trackByFn" class="option-item-lvl2">
                      <span class="option-lvl2" (click)="onChangeOption(optionLvl2, optionLvl1.type)">
                         <span class="label normal">{{ optionLvl2[optionLvl1.property.title] | translate }}
                           <span
                             *ngIf="isNativeSubject(optionLvl2)"
                             class="subject-category normal">{{'subject-filters.native-language' | translate}}</span>
                           <span
                             *ngIf="isForeignSubject(optionLvl2)"
                             class="subject-category normal">{{'subject-filters.foreign-language' | translate}}</span>
                         </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </ng-template>

    <ng-template #flatList>
      <div class="options">
        <div class="scroll">
          <ul class="options-list">
            <li *ngFor="let optionLvl1 of filter.options; let i = index; trackBy: trackByFn" class="option-item">
              <ng-template [ngIf]="filter.type === 'checkbox'">
                <label class="option-wrap">
                  <input type="checkbox"
                         [value]="optionLvl1"
                         [checked]="checked(optionLvl1)"
                         (change)="onChangeOption(optionLvl1)"
                         class="input">
                  <i class="input-checkbox">
                    <app-icon width="10" height="10" icon="checkbox-arrow"></app-icon>
                  </i>
                  <span class="label normal">{{ optionLvl1[filter.property.title] | translate }}</span>
                </label>
              </ng-template>
              <ng-template [ngIf]="filter.type === 'radio'">
                <label class="option-wrap">
                  <input type="radio"
                         [value]="optionLvl1"
                         [checked]="checked(optionLvl1)"
                         (change)="onChangeOption(optionLvl1)"
                         class="input">
                  <i class="input-radio">
                    <i class="checked"></i>
                  </i>
                  <span class="label normal">{{ optionLvl1[filter.property.title] | translate }}</span>
                </label>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
